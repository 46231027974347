import ScrollToTop from '../components/ScrollToTop';
import validate from '../xcl_modules/validate';
import { useState, useEffect } from 'react';
import Header from "../components/Header";
import createStyles from '../xcl_modules/createStyles';
import dropdownstyles from '../xcl_modules/dropdownstyle';
import { Link, useNavigate } from "react-router-dom";
import saveStepIcon from '../css/icons/icon-arrowright-drk.png';
import prevStepIcon from '../css/icons/icon-arrowleft.png';
import getTokenData from '../xcl_modules/tokenData';
import getUserData from '../xcl_modules/userData';
import getProfiles from '../xcl_modules/profiles';
import useDropdownMenu from 'react-accessible-dropdown-menu-hook';
import Swal from 'sweetalert2';
import InfoAnonUser from '../components/InfoAnonUser';
import InfoAnonEmailDomain from '../components/InfoAnonEmailDomain';
import InfoEnterLRSCredentials from '../components/InfoEnterLRSCredentials';

const ProfileLRS = () => {

  const [inputs, setInputs] = useState( {} );
  const [copyFrom, setCopyFrom] = useState( "Select to copy LRS settings" );
  const [infoAnonUser, setInfoAnonUser] = useState(false);
  const [infoAnonEmailDomain, setInfoAnonEmailDomain] = useState(false);
  const [infoLRSDeets, setInfoLRSDeets] = useState(false);

  const navigate = useNavigate();

  const profiles  = getProfiles();
  const thisPID = localStorage.getItem("editProfileID") ? parseInt(localStorage.getItem("editProfileID")) : 0;
  
  // groom the profiles to remove this one
  let index = 0;
  profiles.forEach((profile, i) => {
    if (profile.ID === thisPID) {
      index = i;
    }
  });
  profiles.splice(index, 1);
  
  const { buttonProps, itemProps, isOpen, setIsOpen } = useDropdownMenu(profiles.length);

  useEffect(() => {
    validate();
    // get data
    const getData = async () => {
      try {
          const userData = getUserData();
          const getEmailDomain = () => {
            let email = (userData.email==="") ? ((userData.emailaddress==="") ? ((userData.username.indexOf("@")) ? userData.username : 'au@buildXCL.com') : userData.emailaddress) : userData.email;
            let emailParts = email.split("@");
            return "@" + emailParts[1];
          }
          const response = await fetch(process.env.REACT_APP_BE+`/linkprofile/${localStorage.getItem("editProfileID")}`, {
              method: 'GET',
              headers: {'Content-Type': 'application/json', 
                  Authorization: `Bearer ${getTokenData().accessToken}`}
          });
          const result = await response.json();

          if (result.status === "success") {
            Object.entries(result.data[0]).forEach(([key, val]) => {
              let nval = val;
              if (key === "lrs_annon_user_name" && val === "") {
                nval = "Anonymous User";
              }
              if (key === "lrs_random_email_domain" && val === "") {
                nval = getEmailDomain();
              }

              setInputs(values => ({...values, [key]: nval }));
            });
          }
          //console.log(inputs);
      } catch {
        Swal.fire({
          position: "top",
          iconHtml:  '<div class="positoast"></div>',
          title: "System Error :O  Please Login again.",
          showConfirmButton: false,
          timer: 1500,
          toast: true,
          timerProgressBar: true,
          showClass: {
              popup: 'toastCongratsOn'
          },
          hideClass: {
              popup: 'toastCongratsOff'
          }
        });
      }
  }
  getData();
  

}, []);

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs(values => ({...values, [name]: value}));
  }

  const handleSubmit = (event) => {
    event.preventDefault();

    const saveData = async () => {
      const lrs_annon_user_name = inputs.lrs_annon_user_name ? inputs.lrs_annon_user_name : "";
      const lrs_random_email_domain = inputs.lrs_random_email_domain ? inputs.lrs_random_email_domain : "";

      try {
          const response = await fetch(process.env.REACT_APP_BE+'/linkprofile', {
              method: 'POST',
              headers: {'Content-Type': 'application/json', 
                  Authorization: `Bearer ${getTokenData().accessToken}`},
              body: JSON.stringify( { 
                ID: localStorage.getItem("editProfileID"), 
                lrs_xcl: inputs.lrs_xcl.toString(),
                lrs_endpoint: (inputs.lrs_xcl===1) ? '' : inputs.lrs_endpoint,
                lrs_username: (inputs.lrs_xcl===1) ? '' : inputs.lrs_username,
                lrs_password: (inputs.lrs_xcl===1) ? '' : inputs.lrs_password,
                lrs_annon_user_name: lrs_annon_user_name, 
                lrs_random_email_domain: lrs_random_email_domain  } )
          });
          const result = await response.json();

          if (result.status === "success") {
            console.log("profile updated");
            navigate("/profilebranding");
          }
      } catch {
        Swal.fire({
          position: "top",
          iconHtml:  '<div class="positoast"></div>',
          title: "System Error :O  Please Login again.",
          showConfirmButton: false,
          timer: 1500,
          toast: true,
          timerProgressBar: true,
          showClass: {
              popup: 'toastCongratsOn'
          },
          hideClass: {
              popup: 'toastCongratsOff'
          }
        });
      }
    }
    saveData();
  }

  const handlePrevNavigation = (event) => {
    event.preventDefault();
    navigate("/profilename");
  }

  const doSelectProfile = (event) => {
    const pID = parseInt(event.target.id);

    profiles.forEach((profile) => {
      if (profile.ID === pID) {
        setCopyFrom(profile.name);
        const keys = ['lrs_endpoint', 'lrs_username', 'lrs_password'];
        keys.forEach((key) => {
          setInputs(values => ({...values, [key]: profile[key] }));
        })
      }
    });
    
    setIsOpen(false);
  }

  const handleChangeInt = (event) => {

    const name = event.target.name;
    const value = parseInt(event.target.value);

    setInputs(values => ({...values, [name]: value}));

  }

  const infoAnonUserClose = () => {
    setInfoAnonUser(false);
  }
  
  const infoAnonUserOpen = () => {
    setInfoAnonUser(true);
  }
  
  const infoAnonEmailDomainClose = () => {
    setInfoAnonEmailDomain(false);
  }
  
  const infoAnonEmailDomainOpen = () => {
    setInfoAnonEmailDomain(true);
  }
  
  const infoLRSDeetsClose = () => {
    setInfoLRSDeets(false);
  }

  const infoLRSDeetsOpen = () => {
    setInfoLRSDeets(true);
  }

  const progress = "50%";

  return (
    <>
      <Header/>
      <ScrollToTop/>
      <style>{createStyles}{dropdownstyles}</style>
      <div className='createnew header'>
        <div className='stepperheader'>
          <Link to="/xcl/profiles">&lt; Back to Dashboard</Link>
            <h3>
              Brand Template 
            </h3>
            <p>
              You'll be able to apply this template to your learning link landing pages. 
            </p>
            <div className='steps'>
              <div className='step prior'>
                <Link to="/profilename">
                  <div className='indicator' title="step 1">&#10003;</div>
                  Template Name
                </Link>
              </div>
              <hr/>
              <div className='step active'>
                <Link>
                  <div className='indicator' title="step 2">2</div>
                  LRS Details
                </Link>
              </div>
              <hr/>
              <div className='step upcoming'>
                <Link to="/profilebranding">
                  <div className='indicator' title="step 3">3</div>
                  Logo & Background Color
                </Link>
              </div>
              <hr/>
              <div className='step upcoming'>
                <Link to="/profilecustomize">
                  <div className='indicator' title="step 4">4</div>
                  Customize Fields
                </Link>
              </div>
            </div>
        </div>
      </div>
      <form className='formContainer' onSubmit={handleSubmit}>
        <h4 id="contentstarts">Enter your LRS details</h4>
        <p>Select if you would like to use the XCL built-in LRS or enter your own LRS details.</p>
        <div style={{ marginTop: "26px" }}>
          <input type='radio' id='lrs_xcl_on' name='lrs_xcl' value='1' onChange={handleChangeInt} checked={((inputs.lrs_xcl===1||inputs.lrs_xcl==="1") ? true : false )}></input>
          <label htmlFor="lrs_xcl_on" className='inlineLabel'>Use the XCL LRS</label>
          <input type='radio' id='lrs_xcl_off' name='lrs_xcl' value='0' onChange={handleChangeInt} checked={((inputs.lrs_xcl===0||inputs.lrs_xcl==="0") ? true : false )}></input>
          <label htmlFor="lrs_xcl_off" className='inlineLabel' style={{ display: "inline-block", width: "160px", marginRight: "10px" }}>Enter my own LRS Details</label><Link className="icon-inlinesupport" onClick={infoLRSDeetsOpen} aria-label='Click for more information how the LRS Anonymous User field is used'></Link>
          <InfoEnterLRSCredentials 
            open={infoLRSDeets}
            onClose={infoLRSDeetsClose} 
          />
        </div>
        <div style={ (inputs.lrs_xcl===0||inputs.lrs_xcl==="0") ? { } : { display:"none" } }>
            { (profiles.length > 0) ? (
            <>
              <label htmlFor='copyfromtemplate'>Copy LRS settings from another template</label>
              <Link name="copyfromtemplate" className={(isOpen) ? 'ddbutton open': 'ddbutton'} {...buttonProps}>
                {copyFrom}
              </Link>
              <div style={{ position: "absolute" }}>
                <div className={"listMenu " + (isOpen ? 'visible' : '')} role='menu' style={{ top: "0px", left: "0px", minWidth: "456px" }}>
                  { profiles.map((profile, i) => (
                    <Link {...itemProps[i]} key={i} id={profile.ID} onClick={doSelectProfile}>{profile.name}</Link>
                  )) }          
                </div>
              </div>
              </>
            ) : ( <></> )}
          <label htmlFor="lrs_endpoint">LRS Endpoint</label>
          <input type="text"
              maxLength="200"
              value={inputs.lrs_endpoint || ""}
              name="lrs_endpoint" 
              onChange={handleChange}
              required={(inputs.lrs_xcl===0||inputs.lrs_xcl==="0")? true : false }
              placeholder="https://yourlrsname.lrs.io/xapi/"></input>
          <label htmlFor="lrs_username">LRS Username (or Key Name)</label>
          <input type="text"
              maxLength="60"
              value={inputs.lrs_username || ""}
              name="lrs_username" 
              onChange={handleChange}
              required={(inputs.lrs_xcl===0||inputs.lrs_xcl==="0")? true : false }
              placeholder="LRS Username (or Key Name)"></input>
          <label htmlFor="lrs_password">LRS Password (or secret)</label>
          <input type="password"
              maxLength="60"
              value={inputs.lrs_password || ""}
              name="lrs_password" 
              onChange={handleChange}
              required={(inputs.lrs_xcl===0||inputs.lrs_xcl==="0")? true : false }
              placeholder="LRS Password (or secret)"></input>
        </div>
        <h4 style={{ marginTop: "45px" }}>Enter your LRS data defaults</h4>
        <p>Enter how would you like to name anonymous users. If your users are not going to be using any identifiable data, 
          customize how you would you like to label that information.</p>
        <label htmlFor="lrs_annon_user_name" style={{ display:"inline-block", width:"160px" }}>LRS Anonymous User</label><Link className="icon-inlinesupport" onClick={infoAnonUserOpen} aria-label='Click for more information how the LRS Anonymous User field is used'></Link>
        <InfoAnonUser 
            open={infoAnonUser}
            onClose={infoAnonUserClose} 
        />
        <input type="text"
            maxLength="60"
            value={inputs.lrs_annon_user_name || ""}
            name="lrs_annon_user_name" 
            onChange={handleChange}
            required
            placeholder="Anonymous User"></input>
        <label htmlFor="lrs_random_email_domain" style={{ display:"inline-block", width:"260px" }}>LRS Anonymous User Email Domain</label><Link className="icon-inlinesupport" onClick={infoAnonEmailDomainOpen} aria-label='Click for more information how the LRS Anonymous User Email Domain field is used'></Link>
        <InfoAnonEmailDomain 
            open={infoAnonEmailDomain}
            onClose={infoAnonEmailDomainClose} 
        />
        <input type="text"
            maxLength="45"
            value={inputs.lrs_random_email_domain || ""}
            name="lrs_random_email_domain" 
            onChange={handleChange}
            required
            placeholder="@youremail.com"></input>
        <div className='formFooter'>
          <div className='progressbarbackground'>
            <div className='progressbarprogress' style={{ width: progress }}></div>
          </div>
          <div className='stepperFormControls'>
          <input type="button" name='backone' value="Previous: Template Name" style={{ backgroundImage: `url('${prevStepIcon}')`}} onClick={handlePrevNavigation}></input>
          <input type="submit" name='subitBtn' value="Next: Branding" style={{ backgroundImage: `url('${saveStepIcon}')`}}></input>
          </div>
        </div>
      </form>
      <div className='end'> </div>
    </>
  );
};

export default ProfileLRS;