import '../css/ob.css';
import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import iconDBClose from '../css/icons/icon-db-close.png';
import iconCongrats from '../css/icons/icon-congrats-purple.png';
import saveStepIcon from '../css/icons/icon-arrowright-drk.png';
import prevStepIcon from '../css/icons/icon-arrowleft.png';
import ob1 from '../img/ob1.png';
import ob2 from '../img/ob2.png';
import ConfettiExplosion  from 'react-confetti-explosion';
import getTokenData from '../xcl_modules/tokenData';
import getUserData from '../xcl_modules/userData';

const ob1Alt = "Three screenshots of learning link landing pages, each with different branding";
const ob2Alt = "Three screenshots of learning link landing pages, each with different branding";

const Onboarding = () => {
    const [open, setOpen] = useState( false );
    const [welcome, setWelcome] = useState( true );
    const [step1, setStep1] = useState( false );
    const [step2, setStep2] = useState( false );

    const largeProps = {
        force: 0.8,
        duration: 3000,
        particleCount: 300,
        particleSize: 22,
        width: 1600,
        colors: ['#00BBF9', '#9B5DE5', '#00F5D4', '#FEE440', '#F15BB5'],
    };
    
    const userData = getUserData();

    useEffect(() => {
        if (userData.onboarded === 0) {
            setOpen(true);
        }
    }, [userData]);

    const doCloseFinished = async () => {
        // set the user attribute onboarded = 1;
        userData.onboarded = 1;
        localStorage.setItem("xcldata", JSON.stringify(userData));
        try {
            //const response = await fetch(process.env.REACT_APP_BE+'/user', {
            await fetch(process.env.REACT_APP_BE+'/user', {
                    method: 'POST',
                headers: {'Content-Type': 'application/json', 
                    Authorization: `Bearer ${getTokenData().accessToken}`},
                body: JSON.stringify( { userID: userData.userID, onboarded: 1 } )
            });
            //const result = await response.json();
            //console.log(result);
            // now update the userData
        } catch (err) {
            console.log(`something went horribly wrong (${err})`);
        }
        setOpen(false);
    }

    const doClose = () => {
        doCloseFinished();
    }

    const goWelcome = () => {
        setWelcome(true);
        setStep1(false);
        setStep2(false);
    }

    const goStep1 = () => {
        setWelcome(false);
        setStep1(true);
        setStep2(false);
    }

    const goStep2 = () => {
        setWelcome(false);
        setStep1(false);
        setStep2(true);
    }

    return (
        <>
        { open ? (
            <div className='obDarkBlanket'>
                <ConfettiExplosion {...largeProps} />
                <div className='obContainer Welcome' style={ (welcome) ? {} : { display: "none"} } >
                    <Link onClick={doClose} style={{ backgroundImage: `url('${iconDBClose}')` }} className='onCloseButton' aria-label='close the onboarding popup'></Link>
                    <img src={iconCongrats} alt="" className='congrats'></img>
                    <h2>Welcome to XCL by Build Capable!</h2>
                    <h4>Get started in two simple steps. </h4>
                    <div className='obStepperControls'>
                        <div className='obStep active' onClick={goWelcome}/>
                        <div className='obStep' onClick={goStep1}/>
                        <div className='obStep'onClick={goStep2}/>
                    </div>
                    <input type="button" value="Let's Go" className='next' style={{ backgroundImage: `url('${saveStepIcon}')`}} onClick={goStep1}></input>
                </div>

                <div className='obContainer Step1' style={ (step1) ? {} : { display: "none"} } >
                    <Link onClick={doClose} style={{ backgroundImage: `url('${iconDBClose}')` }} className='onCloseButton' aria-label='close the onboarding popup'></Link>
                    <h2>Step 1: Create a Branded Template</h2>
                    <h4>Select the XCL LRS or enter your own LRS credentials, and customize your landing page by uploading a 
                        logo, selecting a background color, and setting fields for learner details or keep it anonymous.</h4>
                    <img className='obStepGraphic' src={ob1} alt={ob1Alt}></img>
                    <div className='obStepperControls'>
                        <div className='obStep' onClick={goWelcome}/>
                        <div className='obStep active' onClick={goStep1}/>
                        <div className='obStep'onClick={goStep2}/>
                    </div>
                    <div className='obNavContainer'>
                        <input type="button" value="Back" className='back' style={{ backgroundImage: `url('${prevStepIcon}')`}} onClick={goWelcome}></input>
                        <input type="button" value="Next" className='next' style={{ backgroundImage: `url('${saveStepIcon}')`}} onClick={goStep2}></input>
                    </div>
                </div>

                <div className='obContainer Step2' style={ (step2) ? {} : { display: "none"} } >
                    <Link onClick={doClose} style={{ backgroundImage: `url('${iconDBClose}')` }} className='onCloseButton' aria-label='close the onboarding popup'></Link>
                    <h2>Step 2: Create a Learning Link</h2>
                    <h4>Apply a brand template, personalize your landing page text, add your content, and publish! It's that simple!</h4>
                    <img className='obStepGraphic' src={ob2} alt={ob2Alt}></img>
                    <div className='obStepperControls'>
                        <div className='obStep' onClick={goWelcome}/>
                        <div className='obStep' onClick={goStep1}/>
                        <div className='obStep active'onClick={goStep2}/>
                    </div>
                    <div className='obNavContainer'>
                        <input type="button" value="Back" className='back' style={{ backgroundImage: `url('${prevStepIcon}')`}} onClick={goStep1}></input>
                        <input type="button" value="Done" className='done' onClick={doCloseFinished}></input>
                    </div>
                </div>

            </div>
        ) : (
            <></>
        )}
        </>
    )
}

export default Onboarding;