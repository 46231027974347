import ScrollToTop from '../components/ScrollToTop';
import validate from '../xcl_modules/validate';
import { useState, useEffect } from 'react';
import Header from "../components/Header";
import createStyles from '../xcl_modules/createStyles';
import { Link, useNavigate } from "react-router-dom";
import saveStepIcon from '../css/icons/icon-arrowright-drk.png';
import getTokenData from '../xcl_modules/tokenData';
import getProfiles from '../xcl_modules/profiles';
import Swal from 'sweetalert2';

const ProfileName = () => {

  const [inputs, setInputs] = useState( {} );

  const navigate = useNavigate();

  const profiles = getProfiles();
  const thisPID = localStorage.getItem("editProfileID") ? parseInt(localStorage.getItem("editProfileID")) : 0;
  
  // groom the profiles to remove this one
  if (profiles.length > 1) {
    let index = 0;
    profiles.forEach((profile, i) => {
      if (profile.ID === thisPID) {
        index = i;
      }
    });
    profiles.splice(index, 1);
  }

  if (localStorage.getItem("editProfileID") === "") {
    navigate("/xcl/profiles");
  }

  useEffect(() => {
    validate();
    // get data
    const getData = async () => {
        try {
            const response = await fetch(process.env.REACT_APP_BE+`/linkprofile/${localStorage.getItem("editProfileID")}`, {
                method: 'GET',
                headers: {'Content-Type': 'application/json', 
                    Authorization: `Bearer ${getTokenData().accessToken}`}
            });
            const result = await response.json();
  
            if (result.status === "success") {
                const nameKey = "profile_name";
                setInputs(values => ({...values, [nameKey]: result.data[0].name }));
            }
        } catch {
          Swal.fire({
            position: "top",
            iconHtml:  '<div class="positoast"></div>',
            title: "System Error :O  Please Login again.",
            showConfirmButton: false,
            timer: 1500,
            toast: true,
            timerProgressBar: true,
            showClass: {
                popup: 'toastCongratsOn'
            },
            hideClass: {
                popup: 'toastCongratsOff'
            }
          });
        }
    }
    getData();
  }, []);

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs(values => ({...values, [name]: value}));
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    // first check the name
    let nameIsDup = false;
    
    profiles.forEach((profile) => {
      if (profile.name === inputs.profile_name && profile.ID !== thisPID) {
        nameIsDup = true;
      }
    })

    const saveData = async () => {
      try {
          const response = await fetch(process.env.REACT_APP_BE+'/linkprofile', {
              method: 'POST',
              headers: {'Content-Type': 'application/json', 
                  Authorization: `Bearer ${getTokenData().accessToken}`},
              body: JSON.stringify( { ID: localStorage.getItem("editProfileID"), name: inputs.profile_name } )
          });
          const result = await response.json();

          if (result.status === "success") {
            console.log("profile updated");
            navigate("/profilelrs");
          }
      } catch {
        Swal.fire({
          position: "top",
          iconHtml:  '<div class="positoast"></div>',
          title: "System Error :O  Please Login again.",
          showConfirmButton: false,
          timer: 1500,
          toast: true,
          timerProgressBar: true,
          showClass: {
              popup: 'toastCongratsOn'
          },
          hideClass: {
              popup: 'toastCongratsOff'
          }
        });
      }
    }
    if (!nameIsDup) {
      saveData();
    } else {
      Swal.fire({
        position: "top",
        iconHtml:  '<div class="correctitoast"></div>',
        title: "You already have a template with this name, please chose a unique name.",
        showConfirmButton: false,
        timer: 2000,
        toast: true,
        timerProgressBar: true,
        showClass: {
            popup: 'toastWarningOn'
          },
        hideClass: {
            popup: 'toastWarningOff'
        }
      });
    }
  }

  const progress = "25%";

  return (
    <>
      <Header/>
      <ScrollToTop/>
      <style>{createStyles}</style>
      <div className='createnew header'>
        <div className='stepperheader'>
          <Link to="/xcl/profiles">&lt; Back to Dashboard</Link>
            <h3>
              Brand Template
            </h3>
            <p>
              Apply this template to your learning link landing pages. 
            </p>
            <div className='steps'>
              <div className='step active'>
                <Link>
                  <div className='indicator' title="step 1">1</div>
                  Template Name
                </Link>
              </div>
              <hr/>
              <div className='step upcoming'>
                <Link to="/profilelrs">
                  <div className='indicator' title="step 2">2</div>
                  LRS Details
                </Link>
              </div>
              <hr/>
              <div className='step upcoming'>
                <Link to="/profilebranding">
                  <div className='indicator' title="step 3">3</div>
                  Logo & Background Color
                </Link>
              </div>
              <hr/>
              <div className='step upcoming'>
                <Link to="/profilecustomize">
                  <div className='indicator' title="step 4">4</div>
                  Customize Fields
                </Link>
              </div>
            </div>
        </div>
      </div>
      <form className='formContainer' onSubmit={handleSubmit}>
        <h4 id="contentstarts">Name your template</h4>
        <p>We recommend you name your template after the company, brand, department or client that you will be creating 
          links for. This name will help you identify it and won't appear on the learning link landing pages.</p>
        <label htmlFor="profile_name">Template Name</label>
          <input type="text"
            maxLength="60"
            value={inputs.profile_name || ""}
            name="profile_name" 
            onChange={handleChange}
            required
            placeholder="North American Sales Leadership Training"></input>
        <div className='formFooter'>
          <div className='progressbarbackground'>
            <div className='progressbarprogress' style={{ width: progress }}></div>
          </div>
          <div className='stepperFormControls'>
            <input type="submit" name='subitBtn' value="Next: LRS Details" style={{ backgroundImage: `url('${saveStepIcon}')`}}></input>
          </div>
        </div>
      </form>
      <div className='end'> </div>
    </>
  );
};

export default ProfileName;